.chat-profile{
    display: flex;
    align-items: center;
    
    gap: 10px;
}

.name{
    margin-top: 10px;
    gap: 5px;
}

