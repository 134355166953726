.Chat {
  position: relative;
  display: grid;
  grid-template-columns: 22% auto;
  gap: 1rem;
  font-family: 'Roboto', sans-serif;
  margin-top: -5px;
}

.Left-side-chat {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 20px;
}
.searchbar-left-chat{
  margin-top: 25px;
  margin-left: 0px;
}
.Chat-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: #000000;
  border-radius: 1rem;
  padding: 1rem;
  height: 82vh;
  min-height: 80vh;
  overflow: scroll;
  margin-bottom: 20px;
  margin-top: 5px;
  
}
.icons-cont{
  margin-top: 25px;
}

.Chat-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Right-side-chat {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.conversation:hover {
  background: #80808038;
  cursor: pointer;
}

.conversation {
  border-radius: 0.5rem;
  padding: 10px;
}

.conversation > div {
  position: relative;
}

.online-dot {
  background-color: greenyellow;
  border-radius: 50%;
  position: absolute;
  left: 2.5rem;
  top: 5px;
  width: 1rem;
  height: 1rem;
}

.chatbox-empty-message {
  display: flex;
  align-self: center;
  justify-content: center;
  font-size: 20px;
}



@media screen and (max-width: 768px) {
 .Chat {
    grid-template-columns: 16% auto;
  }
   .follower.conversation>div>.name{
    display: none;
   }
}