.share{
    display: flex;
    gap: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    background-color: transparent;
}
.mimage{
 border-radius: 50%;
 width: 3rem;
 height: 3rem;
}
.search{
    display: flex;
    flex-direction:column;
    width: 90%;
    gap: 1rem;
}
.searchbar{
    --input-focus: #2d8cf0;
    --font-color: #323232;
    --font-color-sub: #666;
    --bg-color: #fff;
    --main-color: #E00712;
    
    border-radius: 5px;
    border: 2px solid var(--main-color);
    background-color: var(--bg-color);
    box-shadow: 4px 4px var(--main-color);
    font-size: 15px;
    font-weight: 600;
    color: var(--font-color);
    padding:  10px;
    outline: none;
  }
  
  .searchbar::placeholder {
    color: var(--font-color-sub);
    opacity: 0.8;
  }
  
  .searchbar:focus {
    border: 2px solid var(--input-focus);
  }

  .main-options{
    display: flex;
    justify-content:space-around;
  }

  .options{
padding:5px;
padding-left: 10px;
padding-right: 10px;
border-radius: 10px;

display: flex;
align-items: center;
justify-content: center;
font-size: 15px;

  }

  
.option:hover{
    cursor: pointer;
  }
.preview{
  position: relative;

}

.preview>svg{

right: 1.5rem;
top: 0.5rem;
cursor: pointer;
  position: absolute;
  color: #E00712;
 
 
}

.preview> img{
  width: 100%;
  max-height: 20rem;
  object-fit: cover;
  border-radius: 0.5rem;
}
.share-btn{
  padding-left: 100px;
  padding-right: 100px;
  font-size: 18px;
}