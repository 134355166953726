
.followerslist{

    width: 100%;
    
    gap: 20px;
    flex-direction: column;
    font-size: 15px;
    color: white;
    

    font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial, sans-serif;
    
}

.followerslist h3{
color:  #E00712;
font-size: 25px;
margin-bottom: 5px;
margin-top: 30px;

display: flex;
justify-content: center;
}
.follower{
display:flex;
justify-content: space-between;
align-items: center;

}



.f-image{
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    margin-bottom: 8px;
    
}
.name{
    display: flex;
    flex-direction: column;
   
   
   
    
 
   
  
}


.first-text{
   font-weight: bold;
   
   
 
}

.second-text{
    font-size: 13px;
  
}

button {
   
    color:white;
    background-color: #E00712;
    font-weight: 600;
    font-size: 14px;
    margin: 0;
    /* padding: 14px 13px 12px 13px; */
    border: 0;
    outline: 1px solid #E00712;
    border-radius: 8px;
    line-height: 1;
    cursor: pointer;
    
    transition: all ease-in-out .3s;
    
  }
  button:hover{
    background-color: transparent;
  }


  .followers-btn{
    padding:10px;
    
  }