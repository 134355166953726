.right{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top:10px;
    
}


.icons{
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;

    justify-content:space-between;
    display: flex;
}


.icons>img{
    width: 2rem;
    height: 2rem;
}


.trend-button-container{
    display: flex;
    justify-content: center;
}
.trend-btn{
   width: 80%;
  
    padding-top: 8px;
    padding-bottom:8px ;
    font-size: 20px;
    
  }

  .main-logo> img{

    width: 300px;
    margin-right: 60px;
    margin-top: 20px;
    height: auto;
  }
  .pagee{
    margin-right: 40px;
  }