.my-profile{
    position: relative;
    display: grid;
    grid-template-columns: 20rem auto 20rem ;
    gap: 2rem;
}


.profile-center{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}